import React from 'react';
import { initializeHubspotForm } from '../../utils/gatsby-helpers';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const Newsletter = ({ id, title, description, form, hideTextsOnSubmit = false }) => {
    let formId = "footer-form-container"
    if (id) {
        formId = id
    }

    let hideTexts = () => {}
    if (hideTextsOnSubmit) {
        hideTexts = () => {
            const formElement = document.getElementById(formId)
            const parentElement = formElement.parentElement
            const children = parentElement.childNodes
            const childrenArray = Array.from(children)
            childrenArray.forEach(child => {
                if (child.id !== formId) {
                    parentElement.removeChild(child)
                }
            })
        }
    }

    initializeHubspotForm(form.portal_id, form.form_id, `#${formId}`, form.region, null, hideTexts)
	
    return <div className="main__section main__section--100">
        <div className="container">
            <div className="tile tile--white">
                <h4>{title}</h4>
                <ReactMarkdown children={description} rehypePlugins={[rehypeRaw]} />
                <div id={formId} className="form-hb form-hb--newsletter" />
            </div>
        </div>
    </div>
};
