import React from 'react';
import { Link } from "gatsby"

const LinkButton = ({button, className = '', extras = null, animation = {}}) => {
    if (button.blank || button.url.startsWith('http')) {
        return <a href={button.url} className={className} target="_blank" rel="noreferrer" {...animation}>
            {button.text}
            {extras}
        </a>
    }

    return <Link to={button.url} className={className} {...animation}>
        {button.text}
        {extras}
    </Link>
}

export default LinkButton
