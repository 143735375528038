import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import useScrollListener from "../../../hooks/useScrollListener"

const FloatingButtons = ({ lang = "en" }) => {
    const [show, setShow] = useState(true)
    const [navClassList, setNavClassList] = useState([])
    const scroll = useScrollListener()
    const path = {
        "en": "/public-financing",
        "es": "/es/financiacion-publica"
    }
    const path2 = {
        "en": "/compliance-legal-legality-tecalis-eidas-qtsp-regulations-compliance-certifications-stamp",
        "es": "/es/cumplimiento-normativo-legal-legalidad-tecalis-eidas-qtsp-regulaciones-compliance-certificaciones-sellos"
    }

    useEffect(() => {
        const _classList = []

        if (scroll.y > 150) {
            _classList.push("hidden")

            if (show) {
                setTimeout(() => setShow(false), 250)
            }
        } else {
            setShow(true)
        }

        setNavClassList(_classList)
    }, [scroll.y, scroll.lastY])

    return (
        show && <div className={`floating-button ${navClassList.join(' ')}`}>
            <Link to={path2[lang]}>
                <div className="floating-button__image">
                    <StaticImage
                        src={"../../../new/resources/home/qtsp.png"}
                        alt="Union europea"
                        placeholder="none"
                    />
                </div>
            </Link>
            <Link to={path[lang]}>
                <div className="floating-button__image">
                    <StaticImage
                        src={"../../../new/resources/home/eu-en.png"}
                        alt="Union europea"
                        placeholder="none"
                    />
                </div>
            </Link>
        </div>
    )
}

export default FloatingButtons
