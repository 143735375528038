import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export const NavCard = ({ link, icon, title, description, size}) => {
	let cardClassName = 'card card--nav'
	if (size === 'sm') {
		cardClassName += ' card--nav--sm'
	}

	return (
		<Link className={cardClassName} to={link}>
			<div className="card__body">
				{size !== 'sm' && <GatsbyImage image={getImage(icon.localFile)} alt={icon.alternativeText} />}
				<div className="card__body__description">
					<div className="description__text">
						<b>{title}</b>
						<ReactMarkdown children={description} rehypePlugins={[rehypeRaw]} />
					</div>
					<i className="icon-arrow-right" />
				</div>
			</div>
		</Link>
	)
}
