import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"


const CardPost = ({
                  name,
                  image,
                  url,
                  date = null,
                  tag = null,
                  tagUrl = null,
                  tagClass = null,
                  tagHighlight = null,
                  tagHighlightSmall = null,
                  description = null,
                  showMore = false,
                  big = false,
                  horizontal = false,
                  office = false,
                  solutions = false,
                  imgClassName = null,
                  blank = false,
                  customClass = null,
                  lang = "en",
                  animation = {},
              }) => {
    let className = "card card--post"

    if (big) {
        className += " card--big"
    }

    if (horizontal) {
        className += " card--post--horizontal"
    }

    if (solutions) {
        className = "card"
    }

    if (customClass) {
        className += ` ${customClass}`
    }

    const showMoreButton = {
        "en": "Read more",
        "es": "Leer más"
    }
    
    let title = <Link to={url}>{name}</Link>

    if (blank) {
        title = <a href={url} target="_blank" rel="noreferrer">{name}</a>
    }


    const content = <>
        <div className="card__header">
            {tagHighlight && <div className="tag tag--highlight tag--highlight--md">{tagHighlight}</div>}
            <GatsbyImage alt={image.alternativeText} image={getImage(image.localFile)} className={imgClassName} />
        </div>
        <div className="card__body">
            <div className="card__body__details">
                {date && <div className="details__date">{date}</div>}
                {tag && !tagUrl && <div className={`tag ${tagClass}`}>{tag}</div>}
                {tag && tagUrl && <Link to={tagUrl} className="tag">{tag}</Link>}
                {tagHighlightSmall && <div className="tag tag--highlight tag--highlight--xs">{tagHighlightSmall}</div>}
            </div>

            {big && <h4>{title}</h4>}
            {!big && <h6>{title}</h6>}

            {description && <div className="card__body__description">
                <ReactMarkdown children={description} rehypePlugins={[rehypeRaw]} />
            </div>}

            {showMore && <span className="card__body__see-more">{showMoreButton[lang]} <i className="icon-arrow-right" /></span>}
        </div>
    </>

    return <div className={className} {...animation}>{content}</div>
}

export default CardPost
